import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home';
import NavBar from './components/NavBar';
import NotFound from './components/NotFound';
import Admin from './components/Admin';
import Login from './components/Login';
import Denied from './components/Denied';
import { isAuthenticated, isRole } from './Auth';
import About from './components/About';
import Footer from './components/Footer';

let url =
  process.env.NODE_ENV === 'production'
    ? 'https://credentialing.chattanoogaallergyclinic.com'
    : 'http://localhost';

function AdminRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isRole('administrator') ? (
          children
        ) : isAuthenticated() ? (
          <Navigate
            to={{
              pathname: '/denied',
              state: { from: location },
            }}
          />
        ) : (
          <Navigate
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated() ? (
          children
        ) : (
          <Navigate
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function App() {
  return (
    <div>
      <BrowserRouter>
        <NavBar />
        <div style={{ padding: '1em' }}>
          <Routes>
            <Route
              exact
              path="/"
              element={
                isAuthenticated() ? (
                  <Home url={url} />
                ) : (
                  <Navigate
                    to={{
                      pathname: '/login',
                      replace: true,
                    }}
                  />
                )
              }
            />
            {/* <PrivateRoute exact path="/">
              <Home url={url} />
            </PrivateRoute> */}
            {/* <AdminRoute path="/admin">
              <Admin url={url} />
            </AdminRoute> */}
            <Route
              path="/admin"
              element={
                isRole('administrator') ? (
                  <Admin url={url} />
                ) : isAuthenticated() ? (
                  <Navigate
                    to={{
                      pathname: '/denied',
                      replace: true,
                    }}
                  />
                ) : (
                  <Navigate
                    to={{
                      pathname: '/login',
                      replace: true,
                    }}
                  />
                )
              }
            />
            <Route path="/login" element={<Login url={url} />} />
            <Route path="/denied" element={Denied} />
            {/* <PrivateRoute path="/about">
              <About url={url} />
            </PrivateRoute> */}
            <Route
              path="/about"
              element={
                isAuthenticated() ? (
                  <About url={url} />
                ) : (
                  <Navigate
                    to={{
                      pathname: '/login',
                      replace: true,
                    }}
                  />
                )
              }
            />
            <Route element={NotFound} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
